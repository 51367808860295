<template>
  <div id="updateBranch" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">ACTUALIZAR DE SUCURSAL</v-row>
      <v-container fluid class="addEnterprise">
        <v-row no-gutters justify="end">
          <v-col
            cols="12"
            lg="5"
            md="5"
            sm="5"
            class="marginCol formularios"
            align-self="center"
          >
            <v-row>
              <div class="form-group mb-2">
                <label for="empresa">Empresa</label>
                <select
                  name="empresa"
                  id="empresa"
                  v-model="branchSelect.empresaId"
                >
                  <option disabled selected value="0">Selecciona</option>
                  <option
                    v-for="enterprise in enterprises"
                    :key="enterprise.id"
                    v-bind:value="enterprise.id"
                  >
                    {{ enterprise.razonSocial }}
                  </option>
                </select>
              </div>
            </v-row>
            <!--NOMBRE-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="name">Nombre</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="branchSelect.nombreSucursal"
                  required
                  maxlength="200"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <!--PAIS-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="country">País</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="branchSelect.pais"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                  readonly
                />
              </div>
            </v-row>
            <!--CODIGO POSTAL CP-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="cp">C.P.</label>
                <input
                  type="text"
                  name="cp"
                  id="cp"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="branchSelect.codigoPostal"
                  required
                  maxlength="5"
                  @keypress="isNumber($event)"
                  @change="consultaDireccion()"
                />
              </div>
            </v-row>
            <!--ESTADO-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="state">Estado</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="branchSelect.estado"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                  readonly
                />
              </div>
            </v-row>
            <!--MUNICIPIO-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="municipality">Municipio</label>
                <input
                  type="text"
                  name="municipality"
                  id="municipality"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="branchSelect.municipio"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                  readonly
                />
              </div>
            </v-row>
            <!--COLONIA-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="suburb">Colonia</label>

                <select
                  name="suburb"
                  id="suburb"
                  v-model="branchSelect.asentamientoId"
                >
                  <option disabled value="0">Selecciona Colonia</option>
                  <option
                    v-for="suburb in suburbs"
                    :key="suburb.id"
                    v-bind:value="suburb.id"
                  >
                    {{ suburb.name }}
                  </option>
                </select>
              </div>
            </v-row>
            <!--CALLE-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="street">Calle</label>
                <input
                  type="text"
                  name="street"
                  id="street"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="branchSelect.calle"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <!--NUMERO EXTERIOR-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="exterior">N° Exterior</label>
                <input
                  type="text"
                  name="exterior"
                  id="exterior"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="branchSelect.numeroExterior"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <!--NUMERO INTERIOR-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="interior">N° Interior</label>
                <input
                  type="text"
                  name="interior"
                  id="interior"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="branchSelect.numeroInterior"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>

            <!--TELEFONO-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="phone">Telefono</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="branchSelect.telefono"
                  required
                  maxlength="10"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <!--CHECKBOX-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="activa">Sucursal activa</label>
                <v-checkbox
                  name="activa"
                  id="activa"
                  autocomplete="false"
                  v-model="branchSelect.activo"
                  size="20"
                ></v-checkbox>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="cancel()">Cancelar</button>
              <button class="botonAmarilloDerecho" @click="validacion()">
                Guardar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Sucursal</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de actualizar la sucursal?
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Sucursal</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      name: "",
      phone: "",
      reason: "",
      errors: [],
      respuesta: "",
      confirmation: false,
      advice: false,
      error: false,
      country: "México",
      state: "",
      cp: "",
      exterior: "",
      interior: "",
      municipality: "",
      suburb: 0,
      street: "",
      address: [],
      suburbs: [],
      enterprises: [],
      enterprise: 0,
      activa: true,

      branchSelect: [],
    };
  },
  methods: {
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    consultaDireccion() {
      console.log("Consulta dirección");
      console.log(this.branchSelect);
      if (this.branchSelect.codigoPostal.length == 5) {
        this.suburbs = [];
        this.show = true;
        axios
          .get(Server + "/direcciones/" + this.branchSelect.codigoPostal, {
            headers: {
              Authorization: localStorage.token,
            },
          })
          .then((response) => {
            //console.log(response);
            this.address = response.data;
            this.branchSelect.estado = this.address[0].estado;
            this.branchSelect.municipio = this.address[0].municipio;
            this.address.forEach((value, index) => {
              this.suburbs.push({
                id: value.asentamientoId,
                name: value.colonia,
              });
            });
            this.show = false;
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      } else {
        console.log("Menor a 5");
      }
    },
    validaCp() {
      console.log(this.branchSelect.codigoPostal);
      var cp = this.branchSelect.codigoPostal;
      if (cp.length != 5) {
        this.errors.push("Código Postal incorrecto");
        return false;
      } else {
        console.log("Correcto");
        console.log(cp.length);
        return false;
      }
    },
    validacion() {
      this.errors = [];
      this.validaCp();
      if (this.branchSelect.name == "" || this.branchSelect.name == " ") {
        this.errors.push("Se debe especificar la Razón Social.");
      }
      if (this.branchSelect.country == "" || this.branchSelect.country == " ") {
        this.errors.push("Se debe especificar el país.");
      }
      if (this.branchSelect.state == "" || this.branchSelect.state == " ") {
        this.errors.push("Se debe especificar el estado.");
      }
      if (
        this.branchSelect.municipality == "" ||
        this.branchSelect.municipality == " "
      ) {
        this.errors.push("Se debe especificar el municipio.");
      }
      if (this.branchSelect.suburb == "" || this.branchSelect.suburb == 0) {
        this.errors.push("Se debe especificar la colonia.");
      }
      if (this.branchSelect.street == "" || this.branchSelect.street == " ") {
        this.errors.push("Se debe especificar la calle.");
      }
      if (this.branchSelect.country == "" || this.branchSelect.country == " ") {
        this.errors.push("Se debe especificar el país.");
      }
      if (this.branchSelect.state == "" || this.branchSelect.state == " ") {
        this.errors.push("Se debe especificar el estado.");
      }
      if (
        this.branchSelect.exterior == "" ||
        this.branchSelect.exterior == " "
      ) {
        this.errors.push("Se debe especificar el número exterior.");
      }
      if (
        this.branchSelect.interior == "" ||
        this.branchSelect.interior == " "
      ) {
        this.errors.push("Se debe especificar el número interior.");
      }

      if (this.branchSelect.phone == "" || this.branchSelect.phone == " ") {
        this.errors.push("Se debe especificar el número interior.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },

    cancel() {
      localStorage.sucursalId = "";
      this.$router.push("/branchList");
    },
    guardar() {
      this.advice = false;
      console.log("llegue");
      var logo = null;
      if (sessionStorage.getItem("base64img") == "null") {
        logo = this.branchSelect.logo;
      } else {
        logo = sessionStorage.getItem("base64img");
      }
      this.show = true;
      axios
        .put(
          Server + "/sucursales/" + this.branchSelect.id,
          {
            Id: this.branchSelect.id,
            EmpresaId: this.branchSelect.empresaId,
            NombreSucursal: this.branchSelect.nombreSucursal,
            Telefono: parseInt(this.branchSelect.telefono),
            Pais: this.branchSelect.pais,
            CodigoPostal: this.branchSelect.codigoPostal,
            AsentamientoId: this.branchSelect.asentamientoId,
            NumeroExterior: this.branchSelect.numeroExterior,
            NumeroInterior: this.branchSelect.numeroInterior,
            Calle: this.branchSelect.calle,
            Activo: this.branchSelect.activo,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.respuesta = "La Sucursal fue actualizada con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.respuesta = "La Sucursal no fue actualizada.";
            this.confirmation = true;
          }
        });
    },
    aux() {
      this.confirmation = false;
      localStorage.sucursalId = "";
      this.$router.push("/branchList");
    },
    recuperar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log("Resultado de empresas");
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      //console.log(localStorage.sucursalId);
      this.show = true;
      axios
        .get(Server + "/sucursales/" + localStorage.sucursalId, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.branchSelect = response.data;
          localStorage.sucursalId = "";
          this.consultaDireccion();
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.recuperar();
  },
};
</script>